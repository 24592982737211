/* eslint-disable no-case-declarations */
/* eslint-disable consistent-return */
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

import { getCookies, removeCookies } from '@helper_cookies';
import { COOKEY_CHAT_CS_STATUS, COOKEY_CHAT_TERMINATE_QUEUES } from './terminate-queues';

dayjs.extend(relativeTime);

export * from './message-unreads';
export * from './message-paginations';
export * from './terminate-queues';
export * from './user';

export const COOKEY_CHAT_ENABLE = 'ce';

export const combinedMessagesData = (magentoMsg, firebaseMsg) => {
    let combinedResult = [];
    if (magentoMsg && firebaseMsg) {
        const magentoData = magentoMsg.messages.map((message) => ({
            createdAt: message.created_at,
            text: message.body_message,
            is_customer_message: message.sender === 1 ? 'True' : 'False',
            customer_name: message.sender,
            agent_name: message.sender,
            is_admin_read: message.is_read === '1',
            is_customer_read: message.is_read_customer === '1',
            customer_id: magentoMsg.customer_id,
            customer_email: magentoMsg.customer_email,
            agent_code: magentoMsg.agent_code,
            filename: message.filename,
            filetype: message.filetype,
            type_magento: 'magento',
        }));
        combinedResult = [...magentoData, ...firebaseMsg];
    }
    return combinedResult;
};

export const formattedMessagesData = (magentoMsg) => {
    let combinedResult = [];
    if (magentoMsg) {
        const magentoData = magentoMsg.messages.map((message) => ({
            createdAt: message.created_at,
            text: message.body_message,
            is_customer_message: message.sender === 1 ? 'True' : 'False',
            customer_name: message.sender,
            agent_name: message.sender,
            is_admin_read: message.is_read === '1',
            is_customer_read: message.is_read_customer === '1',
            customer_id: magentoMsg.customer_id,
            customer_email: magentoMsg.customer_email,
            agent_code: magentoMsg.agent_code,
            filename: message.filename,
            filetype: message.filetype,
            type_magento: 'magento',
        }));
        combinedResult = [...magentoData];
    }
    return combinedResult;
};

export const getUrlFilename = (url) => {
    let filename = url;

    const parsedUrl = url.split('/');
    if (parsedUrl.length) {
        filename = parsedUrl[parsedUrl.length - 1];
    }

    return filename;
};

// https://stackoverflow.com/questions/61237564/how-can-i-show-days-by-group-like-whatsapp-chat-screen
const groupedDays = (messages) => {
    if (messages && messages.length > 0) {
        const dataTest = messages.reduce((acc, el) => {
            const messageDay = dayjs(el.createdAt).format('YYYY-MM-DD');
            if (acc[messageDay]) {
                return {
                    ...acc,
                    [messageDay]: acc[messageDay].concat([el]),
                };
            }
            return {
                ...acc,
                [messageDay]: [el],
            };
        }, {});

        return dataTest;
    }
};

export const generateGroupedMessages = (messages) => {
    if (messages && messages.length > 0) {
        const days = groupedDays(messages);
        const sortedDays = Object.keys(days).sort((x, y) => dayjs(x, 'YYYY-MM-DD').unix() - dayjs(y, 'YYYY-MM-DD').unix());
        const items = sortedDays.reduce((acc, date) => {
            const sortedMessages = days[date].sort((x, y) => new Date(x.createdAt) - new Date(y.createdAt));
            return acc.concat([{ type: 'day', date, id: date }, ...sortedMessages]);
        }, []);
        return items;
    }
    return [];
};

export const relativeTimeFrom = (date) => {
    if (date) {
        return dayjs(date).fromNow(true);
    }
    return dayjs().fromNow(true);
};

export const clearChatCookies = () => {
    if (getCookies(COOKEY_CHAT_CS_STATUS)) {
        removeCookies(COOKEY_CHAT_CS_STATUS);
    }

    if (getCookies(COOKEY_CHAT_TERMINATE_QUEUES)) {
        removeCookies(COOKEY_CHAT_TERMINATE_QUEUES);
    }
};
