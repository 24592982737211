import firebase from 'firebase/app';
import { features } from '@config';

require('firebase/auth');
require('firebase/messaging');
require('firebase/firestore');

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const env = typeof window !== 'undefined' ? window.APP_ENV : process.env.APP_ENV;
const firebaseConfig = features.chatSystem.config[env ?? 'local'];

export default !firebase.apps.length ? firebase.initializeApp(firebaseConfig) : firebase.app();
