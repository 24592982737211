import { gql } from '@apollo/client';

export const getSessionMessageListSchema = gql`
    query getSessionList($agent_code: String, $customer_email: String) {
        getSessionMessageList(agent_code: $agent_code, customer_email: $customer_email, pageSize: 1000, currentPage: 1) {
            agent_code
            auto_terminate
            answered
            chat_id
            created_at
            customer_email
            customer_id
            customer_name
            ip_address
            is_read
            last_message {
                time
                message
            }
            session
            updated_at
        }
    }
`;

export const getMessageListSchema = gql`
    query getMessageList($chat_session_id: Int!, $pageSize: Int, $currentPage: Int) {
        getMessageList(
            chat_session_id: $chat_session_id
            pageSize: $pageSize
            currentPage: $currentPage
            sort: { sort_direction: DESC, sort_field: created_at }
        ) {
            chat_id
            customer_email
            customer_id
            customer_name
            messages {
                body_message
                message_id
                created_at
                is_robot
                question_id
                updated_at
                sender
                is_read
                is_read_customer
                filename
                filetype
            }
            agent_code
            allow_terminate
        }
    }
`;

export const addMessageSchema = gql`
    mutation sendMessage(
        $body_message: String!
        $chat_session_id: Int!
        $customer_email: String!
        $customer_id: Int
        $customer_name: String!
        $is_robot: Int!
        $agent_code: String!
        $sender: Int!
        $file: String
        $file_name: String
    ) {
        addMessage(
            input: {
                body_message: $body_message
                chat_session_id: $chat_session_id
                customer_email: $customer_email
                customer_id: $customer_id
                customer_name: $customer_name
                is_robot: $is_robot
                agent_code: $agent_code
                sender: $sender
                file: $file
                file_name: $file_name
            }
        ) {
            body_message
            chat_message_id
            chat_session_id
            created_at
            customer_email
            customer_id
            customer_name
            is_robot
            product_id
            agent_code
            updated_at
        }
    }
`;

export const terminateChatSessionSchema = gql`
    mutation terminateChatSession($chatId: Int!) {
        terminateChatSession(input: { chat_id: $chatId }) {
            status
        }
    }
`;

export const csAllowedChatSchema = gql`
    mutation csAllowedChat($allowed: Int!, $agentCode: String!) {
        CSAllowedChat(input: { allowed: $allowed, agent_code: $agentCode }) {
            status
        }
    }
`;

export const markUnreadMessage = gql`
    mutation markUnreadMessage($sender: Int!, $chatSessionId: Int!) {
        markUnreadMessage(input: { sender: $sender, chat_session_id: $chatSessionId }) {
            status
        }
    }
`;

export const getAgentListSchema = gql`
    query getAgentList($role: String) {
        getAgentList(role: $role) {
            agent_id
            agent_name
            agent_code
        }
    }
`;

export const createFirebaseDocSchema = gql`
    mutation createFirebaseDoc($agent_code: String!, $agent_name: String!, $customer_email: String!, $customer_name: String!, $phone_number: String) {
        createFirebaseDocument(
            input: {
                agent_code: $agent_code
                agent_name: $agent_name
                customer_email: $customer_email
                customer_name: $customer_name
                phone_number: $phone_number
            }
        ) {
            status
        }
    }
`;

export const addActiveSessionSchema = gql`
    mutation addActiveSession($agent_code: String!, $customer_email: String!) {
        addActiveSession(input: { agent_code: $agent_code, customer_email: $customer_email }) {
            status
        }
    }
`;

export const getScoreLoyaltyPerformance = gql`
    query getScoreLoyaltyPerformance($vendor_code: String!) {
        getScoreLoyaltyPerformance(input: { vendor_code: $vendor_code }) {
            company_code
            level_code
            level_label
            total_sales
            final_score
            performance {
                score_code
                standard
                value
                score
            }
        }
    }
`;

export const getAutotextSellerSchema = gql`
    query getAutotextSeller {
        getStoreConfig(path: "swiftoms_general/medbiz_chat/autotext_seller")
    }
`;

export const getAutotextCSSchema = gql`
    query getAutotextCS {
        getStoreConfig(path: "swiftoms_general/medbiz_chat/autotext_cs")
    }
`;

export const getLevelConfigByEmail = gql`
query getLevelConfigByEmail($email: String) {
    getLevelConfigByEmail(email: $email) {
      benefit
      company_code
      email
      level_code
      level_logo
      name
      platform_fee
      score_min
      threshold_max
      threshold_min
    }
  }
`;

export const getLevelBuyerByEmail = gql`
query getLevelBuyerByEmail($email: String!) {
    getLevelBuyerByEmail(email: $email) {
      company_id
      level_code
      level_logo
      level_name
    }
  }  
`;
