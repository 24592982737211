import Cookies from 'js-cookie';
import { print } from 'graphql/language/printer';

import requestGraphInternal from '@graphql_request_internal';
import { custDataNameCookie } from '@config';
import { setCookies } from '@helper_cookies';
import firebaseApp from '@lib_firebase/index';

import { COOKEY_CHAT_CS_STATUS } from '@modules/vendorchat/helpers/index';
import { csAllowedChatSchema } from '@modules/vendorchat/services/graphql/schema';

export const filteredUser = (keyword, users) => {
    if (users && users.length > 0) {
        if (keyword) {
            return users.filter((user) => user.customer_name.toLowerCase().includes(keyword.toLowerCase()));
        }
        return users;
    }
    return [];
};

export const generateCombinedUser = (sessionMessage, firebaseMessage) => {
    const mergedDataUser = [];
    if (sessionMessage && firebaseMessage) {
        sessionMessage.forEach((session) => {
            if (session.agent_code) {
                // const data = {
                //     ...session,
                //     chatId: `${session.customer_email}-${session.agent_code}`,
                //     chat_session_id: session.chat_id,
                //     customer_name: session.customer_name,
                //     lastMessage: session.last_message,
                // };
                // mergedDataUser.push(data);
                firebaseMessage.forEach((firebase) => {
                    const searchParams = new URLSearchParams(`?email=${session.customer_email}`);
                    if (`${searchParams.get('email')}-${session.agent_code}` === firebase.chatId) {
                        const data = {
                            ...firebase,
                            auto_terminate: session.auto_terminate,
                            chat_session_id: session.chat_id,
                            customer_name: session.customer_name,
                            lastMessage: session.last_message,
                            session: session.session,
                            updated_at: session.updated_at,
                        };

                        const hasMerged = mergedDataUser.find(({ chat_session_id }) => chat_session_id === data.chat_session_id);
                        if (!hasMerged) {
                            mergedDataUser.push(data);
                        }
                    }
                });
            }
        });
    }
    return mergedDataUser;
};

export const initialName = (name) => {
    const [firstname = '', lastname = ''] = name.split(' ');
    return `${firstname.substring(0, 1).toUpperCase()}${lastname.substring(0, 1).toUpperCase()}`;
};

export const updateCsChatStatus = async (allowed = 1) => {
    const authUser = Cookies.getJSON(custDataNameCookie) || {};

    if (authUser?.group?.code !== 'Customer Service') return;

    try {
        const agentCode = authUser?.chat_agent_code;
        if (agentCode) {
            await requestGraphInternal(print(csAllowedChatSchema), { agentCode, allowed });

            setCookies(COOKEY_CHAT_CS_STATUS, allowed);
            console.log('cs chat status updated');
        }
    } catch (error) {
        console.error('update cs chat status failed:', error.message);
    }
};

// set firebase authUser as online/offline
export const updateFbUserStatus = async (status = '1', db) => {
    const authUser = Cookies.getJSON(custDataNameCookie) || {};

    if (!authUser?.chat_agent_code || !authUser?.email) return;

    let dbInstance = db;
    if (!dbInstance) dbInstance = firebaseApp.firestore();

    const statusDoc = await dbInstance.collection('status').doc(authUser.chat_agent_code).get();
    if (statusDoc && statusDoc.exists) {
        // update status doc if exist
        await statusDoc.ref.update({ status });
    } else {
        // create status doc if not exist
        await statusDoc.ref.set({ status });
    }

    // update online status when auth user as customer (can chat with cs)
    if (authUser?.group?.code !== 'Customer Service') {
        const statusCustDoc = await dbInstance.collection('status').doc(authUser.email).get();
        if (statusCustDoc && statusCustDoc.exists) {
            // update status doc if exist
            await statusCustDoc.ref.update({ status });
            return;
        }
        // create status doc if not exist
        await statusCustDoc.ref.set({ status });
    }
};

export const uniqueUsers = (prevUsers, newUsers) => {
    const filteredPrevUsers = prevUsers.filter((prevUser) => {
        const duplicateUser = newUsers.find((newUser) => newUser.chatId === prevUser.chatId);
        return !duplicateUser;
    });
    return [...filteredPrevUsers, ...newUsers];
};
