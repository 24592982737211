export const mergeMessageList = (prevMessageList, newMessageList) => {
    const { messages: prevMessages } = prevMessageList;

    const newMessages = newMessageList?.messages || [];

    if (newMessages.length) {
        return {
            customer_id: newMessageList?.customer_id,
            customer_email: newMessageList?.customer_email,
            agent_code: newMessageList?.agent_code,
            messages: prevMessages.concat(newMessages),
        };
    }

    return prevMessageList;
};

export const uniqueMessageListMessages = (messages) => {
    const uniqueMessages = messages.reduce((result, message) => {
        const hasAddedMessage = result.find((msg) => msg.message_id === message.message_id);
        if (!hasAddedMessage) {
            return [...result, message];
        }

        return result;
    }, []);

    return uniqueMessages.map((umsg) => {
        let { is_read, is_read_customer } = umsg;

        if (umsg.is_read === '0') {
            const hasAdminReaded = messages.find((msg) => msg.message_id === umsg.message_id && msg.is_read === '1');
            if (hasAdminReaded) is_read = '1';
        }
        if (umsg.is_read_customer === '0') {
            const hasCustReaded = messages.find((msg) => msg.message_id === umsg.message_id && msg.is_read_customer === '1');
            if (hasCustReaded) is_read_customer = '1';
        }

        return { ...umsg, is_read, is_read_customer };
    });
};
