/* eslint-disable import/no-extraneous-dependencies */
// eslint-disable-next-line object-curly-newline
import { ApolloClient, HttpLink, InMemoryCache, from } from '@apollo/client';
import { IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import { RetryLink } from 'apollo-link-retry';
import { onError } from 'apollo-link-error';
import fetch from 'isomorphic-unfetch';

import { getAppEnv } from '@helpers/env';
import { removeIsLoginFlagging } from '@helper_auth';
import { getCookies } from '@helper_cookies';
import { graphqlEndpoint, HOST, graphqlEndpointMultiple } from '@root/swift.config.js';

import { COOKEY_CHAT_ENABLE, updateCsChatStatus, updateFbUserStatus } from '@modules/vendorchat/helpers';

const fragmentMatcher = new IntrospectionFragmentMatcher({
    introspectionQueryResultData: {
        __schema: {
            types: [],
        },
    },
});

const appEnv = getAppEnv();
const host = HOST[appEnv] ? HOST[appEnv] : HOST.dev;

const uri = graphqlEndpoint[appEnv] ? graphqlEndpoint[appEnv] : graphqlEndpoint.dev;
const uriInternal = `${host}/graphql`;
const uriMultipleName = graphqlEndpointMultiple.name;
const uriMultiple = graphqlEndpointMultiple[appEnv] ? graphqlEndpointMultiple[appEnv] : graphqlEndpointMultiple.dev;

// handle if token expired
const logoutLink = onError((err) => {
    const { graphQLErrors, networkError } = err;
    if (networkError && typeof window !== 'undefined' && graphQLErrors && graphQLErrors.length > 0 && graphQLErrors[0].status > 500) {
        window.location.href = '/maintenance';
    } else if (graphQLErrors && graphQLErrors[0] && graphQLErrors[0].status === 401 && typeof window !== 'undefined') {
        const isChatEnable = !!getCookies(COOKEY_CHAT_ENABLE);
        if (isChatEnable) {
            updateFbUserStatus(0);
            updateCsChatStatus(0);
        }

        removeIsLoginFlagging();
        window.location.href = '/login';
    } else if (networkError && networkError.statusCode === 413 && typeof window !== 'undefined') {
        networkError.message = 'Your file is too large. Please choose smaller file size.';
    }
});

const link = new RetryLink().split(
    (operation) => operation.getContext().request === 'internal',
    new HttpLink({
        uri: uriInternal, // Server URL (must be absolute)
        credentials: 'same-origin', // Additional fetch() options like `credentials` or `headers`
        fetch,
    }),
    new RetryLink().split(
        (operation) => operation.getContext().request === `${uriMultipleName}`,
        new HttpLink({
            uri: uriMultiple, // Server URL (must be absolute)
            credentials: 'same-origin', // Additional fetch() options like `credentials` or `headers`
            fetch,
            useGETForQueries: true,
        }),
        new HttpLink({
            uri, // Server URL (must be absolute)
            credentials: 'same-origin', // Additional fetch() options like `credentials` or `headers`
            fetch,
            useGETForQueries: true,
        }),
    ),
);

export default function createApolloClient(initialState, ctx) {
    // The `ctx` (NextPageContext) will only be present on the server.
    // use it to extract auth headers (ctx.req) or similar.
    return new ApolloClient({
        ssrMode: Boolean(ctx),
        link: from([logoutLink, link]),
        cache: new InMemoryCache({
            typePolicies: {
                NegotiableQuote: { keyFields: ['uid'] },
                NegotiableQuoteComment: { keyFields: ['uid'] },
                awHelpdesk2Ticket: { keyFields: ['uid'] },
            },
            fragmentMatcher,
        }).restore(initialState),
        // eslint-disable-next-line no-underscore-dangle
        connectToDevTools: typeof window !== 'undefined' && window.__APOLLO_CLIENT__ && appEnv === 'local',
        resolvers: {},
    });
}
