import Cookies from 'js-cookie';
import dayjs from 'dayjs';

import { nameGlobalCookie } from '@config';
import { getCookies, removeCookies, setCookies } from '@helper_cookies';

export const COOKEY_CHAT_CS_STATUS = 'ccsac'; // (chat_cs_allowed_chat)
export const COOKEY_CHAT_TERMINATE_QUEUES = 'ctqs'; // prefix cookies key (chat_terminate_queues)
export const COOKEY_CHAT_PREFIX_TERMINATE_QUEUE = 'ctq_';

export const DEFAULT_TERMINATE_TIMEOUT = 5; // in minutes

export const USER_STATUSSES = {
    0: 'offline',
    1: 'online',
};

export const checkIsAllowedAutoTerminate = (usr) => !Number(usr?.is_terminate) && !!Number(usr.auto_terminate) && usr?.agent_code;

export const getTerminateQueues = () => {
    const terminateQueues = getCookies(COOKEY_CHAT_TERMINATE_QUEUES);

    let formattedTermQueues = [];
    if (terminateQueues) {
        formattedTermQueues = terminateQueues.map((termcode) => {
            const [chatSessionId, remainTimeout] = termcode.split('.');
            return [chatSessionId.replace(COOKEY_CHAT_PREFIX_TERMINATE_QUEUE, ''), remainTimeout];
        });
    }

    return formattedTermQueues;
};

export const getTerminateQueue = (user) => {
    const terminateQueues = getCookies(COOKEY_CHAT_TERMINATE_QUEUES);
    if (terminateQueues) {
        // eslint-disable-next-line arrow-body-style
        const terminateQueue = terminateQueues.find((cachedTermcode) => {
            return cachedTermcode.includes(`${COOKEY_CHAT_PREFIX_TERMINATE_QUEUE}${user.chat_session_id}.`);
        });

        if (!terminateQueue) return [];

        const [chatSessionId, ...termQueue] = terminateQueue.split('.');

        return [chatSessionId.replace(COOKEY_CHAT_PREFIX_TERMINATE_QUEUE, ''), ...termQueue];
    }

    return [];
};

export const storeTerminateQueue = (user) => {
    const uatTs = user?.updated_at;
    const lastMsgTs = user?.lastMessage?.time;

    // get the latest activity from user
    let sat;
    if (uatTs && lastMsgTs) {
        sat = dayjs(uatTs);

        // use last_message.update_at if update_at < last_message.update_at
        if (sat.unix() < dayjs(lastMsgTs).unix()) {
            sat = dayjs(lastMsgTs);
        }
    } else {
        sat = dayjs(uatTs ?? lastMsgTs);
    }

    let eat = sat.add(DEFAULT_TERMINATE_TIMEOUT, 'minute'); // end_at (chat will terminate at)
    let termcode = `${COOKEY_CHAT_PREFIX_TERMINATE_QUEUE}${user.chat_session_id}.${eat.unix()}.0`; // terminate data {chat_session_id}.{end_at}.{is_terminate}

    let updatedTerminateQueues;
    const terminateQueues = getCookies(COOKEY_CHAT_TERMINATE_QUEUES);
    if (terminateQueues) {
        updatedTerminateQueues = [...terminateQueues];
        // eslint-disable-next-line arrow-body-style
        const terminateQueueIdx = updatedTerminateQueues.findIndex((cachedTermcode) => {
            return cachedTermcode.includes(`${COOKEY_CHAT_PREFIX_TERMINATE_QUEUE}${user.chat_session_id}.`);
        });

        // create / update cookies
        if (terminateQueueIdx < 0) {
            // use current date if (last_message.update_at / update_at) < current date
            if (sat.unix() < dayjs().unix()) {
                sat = dayjs();
            }
            eat = sat.add(DEFAULT_TERMINATE_TIMEOUT, 'minute'); // end_at (chat will terminate at)
            termcode = `${COOKEY_CHAT_PREFIX_TERMINATE_QUEUE}${user.chat_session_id}.${eat.unix()}.0`; // terminate data {chat_session_id}.{end_at}.{is_terminate}

            updatedTerminateQueues.push(termcode);
        } else {
            const [, , isTerminate] = updatedTerminateQueues[terminateQueueIdx].split('.');

            if (!Number(isTerminate)) {
                updatedTerminateQueues.splice(terminateQueueIdx, 1, termcode);
            }
        }
    } else {
        updatedTerminateQueues = [termcode];
    }

    setCookies(COOKEY_CHAT_TERMINATE_QUEUES, updatedTerminateQueues);
};

export const clearTerminateQueue = (user) => {
    const terminateQueues = getCookies(COOKEY_CHAT_TERMINATE_QUEUES);
    if (terminateQueues) {
        // eslint-disable-next-line arrow-body-style
        const terminateQueueIdx = terminateQueues.findIndex((cachedTermcode) => {
            return cachedTermcode.includes(`${COOKEY_CHAT_PREFIX_TERMINATE_QUEUE}${user.chat_session_id}.`);
        });
        if (terminateQueueIdx < 0) return true;

        const updatedTerminateQueues = [
            ...terminateQueues.filter((termcode) => !termcode.includes(`${COOKEY_CHAT_PREFIX_TERMINATE_QUEUE}${user.chat_session_id}`)),
        ];
        if (updatedTerminateQueues.length === 0) {
            removeCookies(COOKEY_CHAT_TERMINATE_QUEUES);
            return true;
        }

        setCookies(COOKEY_CHAT_TERMINATE_QUEUES, updatedTerminateQueues);
        return true;
    }

    return true;
};

export const clearTerminateQueues = () => {
    const terminateQueues = getCookies(COOKEY_CHAT_TERMINATE_QUEUES);
    if (terminateQueues) {
        removeCookies(COOKEY_CHAT_TERMINATE_QUEUES);
        return true;
    }

    return true;
};

export const clearOldTerminateQueues = () => {
    const allcookies = Cookies.getJSON(nameGlobalCookie);

    Object.keys(allcookies).forEach((key) => {
        if (key.includes(`${COOKEY_CHAT_TERMINATE_QUEUES}.`)) {
            removeCookies(key);
        }
    });
};
