export const readLocalUnreadMsgs = (user, callback = () => null) => {
    const userParams = new URLSearchParams(`?id=${user.chatId}`);

    const localMsgs = JSON.parse(localStorage.getItem('UNREAD_MESSAGES') ?? '[]');
    const unreadChatMsgs = localMsgs.filter((msg) => msg === userParams.get('id'));

    if (unreadChatMsgs.length > 0) {
        const updatedLocalMsgs = localMsgs.filter((msg) => msg !== userParams.get('id'));
        localStorage.setItem('UNREAD_MESSAGES', JSON.stringify(updatedLocalMsgs));

        callback();
    }
};

export const notifyLocalUnreadMsgs = (newMsgs = []) => {
    const totalMsgs = newMsgs.length;

    const localMsgs = JSON.parse(localStorage.getItem('UNREAD_MESSAGES') ?? '[]');
    const localTotalMsgs = localMsgs.length;

    const remappedNewMsgs = newMsgs.map((msg) => {
        // replace special character
        const userParams = new URLSearchParams(`?email=${msg.customer_email}`);
        return `${userParams.get('email')}-${msg.agent_code}`;
    });

    if (totalMsgs > 0 && localTotalMsgs !== totalMsgs) {
        const hasNotifieds = remappedNewMsgs.filter((newMsg) => localMsgs.find((msg) => newMsg === msg));
        const shouldNotify = hasNotifieds.length < remappedNewMsgs.length;

        if (shouldNotify) {
            localStorage.setItem('UNREAD_MESSAGES', JSON.stringify([...new Set(remappedNewMsgs)]));

            if (Notification.permission !== 'granted') {
                Notification.requestPermission();
                return;
            }
            // eslint-disable-next-line no-unused-vars
            const notify = new Notification('Medbiz Seller', {
                icon: '/assets/img/swiftpwa_192.png',
                body: `you have ${totalMsgs} messages`,
            });
        }
    }
};
